import * as Yup from "yup";
import utils from "./utils";
import {
  REQUIRED,
  EMAIL,
  NUMBER,
  INTEGER,
  PERCENTAGE,
  CELLPHONE,
  PHONE,
  MONEY
} from "constants/Validations";

const isValidPhone = (number = "", cellphone = false) => {
  if (!number) {
    return true;
  }
  number = number.replace(/\s/g, "");
  let phoneRegex;
  if (cellphone) {
    phoneRegex = /^\+?([0-9]{2})([0-9]{10})$/;
  } else {
    phoneRegex = /^([0-9]{10})$/;
  }
  return phoneRegex.test(number);
};

const isNumber = num => !Number.isNaN(Number(num)) || !num;

const isPercentage = (value = "") => {
  // validate numbers that are less tha 100
  if (!value) {
    return true;
  }
  value = value.replace(/\s/g, "");
  let percentageRegex;
  // percentageRegex = /\b(?<!\.)(?!0+(?:\.0+)?%)(?:\d|[1-9]\d|100)(?:(?<!100)\.\d+)?%/;
  percentageRegex = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
  return percentageRegex.test(value);
};

const isMoney = (value = "") => {
  if (!value) {
    return true;
  }
  value = value.replace(/\s/g, "");
  let moneyRegex;
  // moneyRegex = /\d(?<!\.\d{2})/;
  moneyRegex = /^\$?[\d,]+(\.\d*)?$/;
  return moneyRegex.test(value);
};

const required = (label = REQUIRED) => Yup.string().required(label);

const percentage = (name = "", required = false, label = PERCENTAGE) =>
  required
    ? Yup.string()
        .required(REQUIRED)
        .test(name, label, value => isNumber(value) | !value)
    : Yup.string().test(name, label, value => isNumber(value) | !value);

const number = (name = "", required = false, label = NUMBER) =>
  !required
    ? Yup.string().test(name, label, value => isNumber(value))
    : Yup.string()
        .required(REQUIRED)
        .test(name, label, value => isNumber(value));

const email = (label = EMAIL) => Yup.string().email(label);

const money = (name = "", required = false, label = MONEY) =>
  !required
    ? Yup.string().test(name, label, value => isMoney(value))
    : Yup.string()
        .required(REQUIRED)
        .test(name, label, value => isMoney(value));

const phone = (
  name = "",
  cellphone = false,
  label = cellphone ? CELLPHONE : PHONE
) => Yup.string().test(name, label, value => isValidPhone(value, cellphone));

export default {
  isValidPhone,
  isNumber,
  isPercentage,
  required,
  percentage,
  number,
  email,
  phone,
  money
};
