import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {languages} from 'constants/Surveys'
import api from 'services/api'
import Select3 from 'components/Select3'
import { FormControl, FormHelperText } from '@material-ui/core'

const Survey = props => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [surveyOk, setSurveyOk] = useState(false)

  const { language, project_id } = props.match.params,
    messages = languages[language];

  const initialValues = {
    project_id: project_id,
    communication: '',
    first_touch: '',
    comprehension_level: '',
    experience_acknowledge: '',
    response_time: '',
    information_quality: '',
    future_touch_probability: '',
    recommendation_probability: '',
    suggestion: ''
  }

  const validationSchema = Yup.object().shape({
    communication: Yup.string().required(messages.requiredMessage),
    first_touch: Yup.string().required(messages.requiredMessage),
    comprehension_level: Yup.string().required(messages.requiredMessage),
    suggestion: Yup.string().required(messages.requiredMessage),
  })

  const submit = async values => {
    setMessage('');
    setLoading(true);
    setSurveyOk(false)
    console.log(values)
    let response = await api.surveys.sendSurvey(values)
    if (response.success) {
      setMessage(messages.success_message);
      setSurveyOk(true);
      setLoading(false);
    } else {
      setMessage(messages.failed_message);
      setLoading(false);
    }
  }

  return (
    <div style={{overflowY: 'auto'}} className="row">
        <div className="col-6 offset-3">
            <div className='jr-card mt-4'>
                <h1>{messages.welcome}</h1>
                <p className="text-justify">{messages.welcome_message_1}</p>
                <p className="text-justify">{messages.welcome_message_2}</p>
                <p className="text-justify">{messages.welcome_message_3}</p>
                <p className="text-justify">{messages.welcome_message_4}</p>
            </div>
            <div className='jr-card'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}
                >
                {({ values, errors, touched, handleChange, setFieldTouched }) => {
                    const change = (name, e) => {
                    e.persist()
                    handleChange(e)
                    setFieldTouched(name, true, false)
                    }
                    return (
                    <Form>
                        <FormControl
                            className="mt-0 mb-2 w-100"
                            error={touched.communication && Boolean(errors.communication)}
                            >
                            <span>{messages.communication_label}</span>
                            <Select3
                                inputId="communication"
                                name="communication"
                                options={messages.quality_options}
                                value={values.communication}
                                onChange={change.bind(
                                null,
                                "communication"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.communication ? errors.communication : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.first_touch && Boolean(errors.first_touch)}
                            >
                            <span>{messages.first_touch_label}</span>
                            <Select3
                                inputId="first_touch"
                                name="first_touch"
                                options={messages.quality_options}
                                value={values.first_touch}
                                onChange={change.bind(
                                null,
                                "first_touch"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.first_touch ? errors.first_touch : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.comprehension_level && Boolean(errors.comprehension_level)}
                            >
                            <span>{messages.comprehension_level_label}</span>
                            <Select3
                                inputId="comprehension_level"
                                name="comprehension_level"
                                options={messages.quality_options}
                                value={values.comprehension_level}
                                onChange={change.bind(
                                null,
                                "comprehension_level"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.comprehension_level ? errors.comprehension_level : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.experience_acknowledge && Boolean(errors.experience_acknowledge)}
                            >
                            <span>{messages.experience_acknowledge_label}</span>
                            <Select3
                                inputId="experience_acknowledge"
                                name="experience_acknowledge"
                                options={messages.quality_options}
                                value={values.experience_acknowledge}
                                onChange={change.bind(
                                null,
                                "experience_acknowledge"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.experience_acknowledge ? errors.experience_acknowledge : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.response_time && Boolean(errors.response_time)}
                            >
                            <span>{messages.response_time_label}</span>
                            <Select3
                                inputId="response_time"
                                name="response_time"
                                options={messages.quality_options}
                                value={values.response_time}
                                onChange={change.bind(
                                null,
                                "response_time"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.response_time ? errors.response_time : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.information_quality && Boolean(errors.information_quality)}
                            >
                            <span>{messages.information_quality_label}</span>
                            <Select3
                                inputId="information_quality"
                                name="information_quality"
                                options={messages.quality_options}
                                value={values.information_quality}
                                onChange={change.bind(
                                null,
                                "information_quality"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.information_quality ? errors.information_quality : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.future_touch_probability && Boolean(errors.future_touch_probability)}
                            >
                            <span>{messages.future_touch_probability_label}</span>
                            <Select3
                                inputId="future_touch_probability"
                                name="future_touch_probability"
                                options={messages.frequency_options}
                                value={values.future_touch_probability}
                                onChange={change.bind(
                                null,
                                "future_touch_probability"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.future_touch_probability ? errors.future_touch_probability : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.recommendation_probability && Boolean(errors.recommendation_probability)}
                            >
                            <span>{messages.recommendation_probability_label}</span>
                            <Select3
                                inputId="recommendation_probability"
                                name="recommendation_probability"
                                options={messages.frequency_options}
                                value={values.recommendation_probability}
                                onChange={change.bind(
                                null,
                                "recommendation_probability"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.recommendation_probability ? errors.recommendation_probability : ""}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            className="mt-4 mb-2 w-100"
                            error={touched.suggestion && Boolean(errors.suggestion)}
                            >
                            <span>{messages.suggestion_label}</span>
                            <TextField
                                inputId="suggestion"
                                name="suggestion"
                                value={values.suggestion}
                                multiline
                                rows={3}
                                onChange={change.bind(
                                null,
                                "suggestion"
                                )}
                                style={{ marginBottom: 0, marginTop: 0 }}
                                fullWidth
                            />
                            <FormHelperText style={{ color: "red" }}>
                                {touched.suggestion ? errors.suggestion : ""}
                            </FormHelperText>
                        </FormControl>
                        {message && (
                            <div className="text-center">
                            <h2 style={{ color: surveyOk ? 'green' : 'red' }}>{message}</h2>
                            </div>
                        )}

                        {
                            !surveyOk &&
                            <div className='mb-3 d-flex align-items-center justify-content-between'>
                                <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                >
                                {messages.sendSurveyButton}
                                </Button>
                            </div>
                        }
                        {loading && (
                            <div className='loader-view'>
                            <CircularProgress />
                            </div>
                        )}

                    </Form>
                    )
                }}
                </Formik>
            </div>
        </div>
    </div>
  )
}

export default Survey
