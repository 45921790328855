import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import storage from "services/storage";

import permissions from "services/permissions";
import { USERS_MODULE } from "constants/permissions";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";
    const data = storage.getData();

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main
            className="app-main-content-wrapper"
            onScroll={() => {
              var cardHeader = document.getElementById("projectHeader");
              if (!!cardHeader) {
                if (cardHeader.offsetTop > 198) {
                  cardHeader.style.marginLeft = "-30px";
                  cardHeader.style.width = "calc(100% + 60px)";
                  cardHeader.style.borderLeftTopRadius = "0px";
                  cardHeader.style.borderRigthTopRadius = "0px";
                  cardHeader.firstChild.firstChild.firstChild.style.borderTopLeftRadius = 0;
                  cardHeader.firstChild.firstChild.firstChild.style.borderTopRightRadius = 0;
                } else {
                  cardHeader.style.width = "100%";
                  cardHeader.style.marginLeft = "0px";
                  cardHeader.firstChild.firstChild.firstChild.style.borderTopLeftRadius =
                    "0.375em";
                  cardHeader.firstChild.firstChild.firstChild.style.borderTopRightRadius =
                    "0.375em";
                }
              }
            }}
          >
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`${match.url}/reports`}
                  component={asyncComponent(() => import("./routes/Reports"))}
                />
                {permissions.hasPermission(USERS_MODULE) && (
                  <Route
                    path={`${match.url}/users`}
                    component={asyncComponent(() => import("./routes/Users"))}
                  />
                )}
                <Route
                  path={`${match.url}/projects`}
                  component={asyncComponent(() => import("./routes/Projects"))}
                />
                <Route
                  path={`${match.url}/developer`}
                  component={asyncComponent(() => import("./routes/Developer"))}
                />
                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
