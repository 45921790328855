import { sendRequest } from "util/utils";
import storage from "services/storage";

const login = async data => {
  const route = "/login";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const createUser = async user => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(user)
    };
  return await sendRequest(route, request);
};

const createPassword = async data => {
  const route = `/user/verifyEmail`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
};

const resetPassword = async data => {
  const route = `/password/reset`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
};

const forgotPassword = async data => {
  const route = `/password/email`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };
  return await sendRequest(route, request);
};

const getUsersList = async () => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getUserById = async id => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const updateUser = async user => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${user.id}`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(user)
    };
  return await sendRequest(route, request);
};

const deleteUser = async id => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${id}`,
    request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getUsersByDependency = async dependence => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/dependencies/users/${dependence}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

export default {
  login,
  createUser,
  createPassword,
  resetPassword,
  forgotPassword,
  getUsersList,
  getUserById,
  updateUser,
  deleteUser,
  getUsersByDependency
};
