import storage from 'services/storage'


/**
 * Recibe el nombre del módulo y acción a revisar
 * retorna si el usuario cuenta o no con permisos para acceder a esa sección (Boolean)
 * 
 * @param {string} moduleName 
 * @param {string} action optional 
 */
const hasPermission = (moduleName, action) => {
    const storageData = storage.getData(),
        permissions = storageData.permissions
    try {
        if (action) {
            return permissions[`${moduleName}_${action}`]
        } else {
            for (let i = 0; i < permissions.length; i++) {
                const permission = permissions[i]
                if (permission.includes(moduleName)){
                    return true
                } 
            }
            return false
        }
    } catch (error) {
        return false
    }
}

export default {
    hasPermission
}