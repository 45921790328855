import projects from "./projects";
import user from "./user";
import emails from "./emails"
import internalNotes from "./internalNotes";
import mainContact from "./mainContact";
import surveys from "./surveys";
import reports from "./reports";

export default {
  projects,
  user,
  emails,
  internalNotes,
  mainContact,
  surveys,
  reports
};
