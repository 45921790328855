import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { REQUIRED, MIN } from "constants/Validations";
import api from "services/api";

const initialValues = {
  email: "",
  password: ""
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED)
    .min(6, MIN(6)),
  confirmPassword: Yup.string()
    .required(REQUIRED)
    .oneOf([Yup.ref("password")], "Las contraseñas deben ser iguales")
});

const ResetPassword = props => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(false);

  const submit = async values => {
    setLoading(true);
    const data = {
        password_token: props.match.params.token,
        password: values.password
      },
      response = await api.user.resetPassword(data);

    setLoading(false);
    if (response.success) {
      setResponseSuccess(true);
    } else {
      setMessage(response.message || "Algo salio mal");
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img
            src={require("assets/images/logoSedeco.png")}
            alt="logo"
            style={{ width: "100%" }}
          />
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Recuperar contraseña</h1>
            <p>Ingrese su nueva contraseña</p>
          </div>

          <div className="app-login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submit}
            >
              {({ values, errors, touched, handleChange, setFieldTouched }) => {
                const change = (name, e) => {
                  e.persist();
                  handleChange(e);
                  setFieldTouched(name, true, false);
                };
                return (
                  <Form>
                    <fieldset>
                      <TextField
                        type="password"
                        name="password"
                        label="Contraseña"
                        fullWidth
                        value={values.password}
                        onChange={change.bind(null, "password")}
                        helperText={touched.password ? errors.password : ""}
                        error={touched.password && Boolean(errors.password)}
                        margin="normal"
                        className="mt-1 my-sm-3"
                      />
                      <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirmar contraseña"
                        fullWidth
                        value={values.confirmPassword}
                        onChange={change.bind(null, "confirmPassword")}
                        helperText={
                          touched.confirmPassword ? errors.confirmPassword : ""
                        }
                        error={
                          touched.confirmPassword &&
                          Boolean(errors.confirmPassword)
                        }
                        margin="normal"
                        className="mt-1 my-sm-3"
                      />

                      {message && (
                        <div>
                          <h2 style={{ color: "red" }}>{message}</h2>
                        </div>
                      )}

                      {responseSuccess && (
                        <div>
                          <h2 style={{ color: "green" }}>
                            Se ha cambiado su contraseña!
                          </h2>
                        </div>
                      )}

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        {loading ? (
                          <div className="text-center w-100">
                            <CircularProgress />
                          </div>
                        ) : responseSuccess ? (
                          <Button
                            onClick={() => props.history.push("/login")}
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Ir a login
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Guardar
                          </Button>
                        )}
                      </div>
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Link to="/login">Iniciar sesión</Link>
                      </div>
                    </fieldset>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
