import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Widget from "components/Widget/index";
import { getIn } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Select3 = props => {
  return props.pretty ? (
    <FormControl error={props.errors} fullWidth={props.fullWidth}>
      <React.Fragment>
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <Select
          inputId={props.name}
          multiple={props.multi || props.multiple}
          renderValue={
            props.multi ? selected => selected.join(", ") : undefined
          }
          errors={props.errors}
          helperText={props.helperText}
          name={props.name}
          value={props.value || ""}
          onChange={props.onChange}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          MenuProps={MenuProps}
        >
          {props.options.map(option => {
            return !props.multi ? (
              <MenuItem
                value={option.value}
                key={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </MenuItem>
            ) : (
              <MenuItem
                value={option.value}
                key={option.value}
                disabled={option.disabled}
              >
                <Checkbox
                  color="primary"
                  checked={props.value.indexOf(option.label) > -1}
                />
                {/*<ListItemText primary={option.label} />*/}
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </React.Fragment>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  ) : (
    <React.Fragment>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <Select
        inputId={props.name}
        errors={props.errors}
        helperText={props.helperText}
        name={props.name}
        value={props.value || ""}
        onChange={props.onChange}
        disabled={props.disabled}
        MenuProps={MenuProps}
        renderValue={props.multi ? selected => selected.join(", ") : undefined}
      >
        {props.options.map(option => {
          return !props.multi ? (
            <MenuItem
              value={option.value}
              key={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem
              value={option.value}
              key={option.value}
              disabled={option.disabled}
            >
              <Checkbox
                color="primary"
                checked={props.value.indexOf(option.label) > -1}
              />
              {/*<ListItemText primary={option.label} />*/}
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </React.Fragment>
  );
};

export default Select3;
