import {
  CLOSE_SNACKBAR,
  USER_REQUEST,
  PROJECT_REQUEST,
  INV_CASE_REQUEST
} from "constants/ActionTypes";

const initialState = {
  user: {
    message: ""
  },
  project: {
    message: ""
  }
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        user: {
          message: action.payload.message,
          success: action.payload.success || state.user.success
        }
      };
    case PROJECT_REQUEST:
      return {
        ...state,
        project: {
          message: action.payload.message,
          success: action.payload.success || state.project.success
        }
      };
    case CLOSE_SNACKBAR:
      return {
        user: {
          message: ""
        },
        project: {
          message: ""
        }
      };
    default:
      return state;
  }
};

export default api;
