import { sendRequest } from "util/utils";
import storage from "services/storage";
import baseUrl from "constants/Api";

const getStatusGraph = async body => {
  const route = "/reports/statuses";
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${storage.getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return await sendRequest(route, request);
};

const getResponsiblesGraph = async body => {
  const route = "/reports/responsible";
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${storage.getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return await sendRequest(route, request);
};

const getInvierteChart = async filters => {
  const route = "/reports/invierte";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(filters)
  };
  return await sendRequest(route, request);
};

const getSectorsChart = async filters => {
  const route = "/sectors";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(filters)
  };
  return await sendRequest(route, request);
};
const getCountryGraph = async body => {
  const route = "/reports/countries";
  const request = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${storage.getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };
  return await sendRequest(route, request);
};

const getResponsibleReport = async r => {
  const route = `/reports${r}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request, true);
};
export default {
  getStatusGraph,
  getResponsiblesGraph,
  getInvierteChart,
  getSectorsChart,
  getCountryGraph,
  getResponsibleReport
};
