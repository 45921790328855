import { sendRequest } from "util/utils";
import storage from "services/storage";

const sendEmail = async (email) => {
    const route = "/emails";
    const request = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${storage.getToken()}`,
        },
        body: email,
    };
    return await sendRequest(route, request);
};

export default {
    sendEmail
};
