export const languages = {
    'es': {
        communication_label: '1. ¿Qué tan clara fue nuestra comunicación con usted? *',
        first_touch_label: '2. ¿Cómo evaluaría su primer contacto con SEDECO? *',
        comprehension_level_label: '3. Califique nuestro nivel de comprensión de sus necesidades empresariales. *',
        experience_acknowledge_label: '4. ¿Cómo califica la experiencia y el conocimiento de los ejecutivos de SEDECO?',
        response_time_label: '5. ¿Cómo califica nuestro tiempo de respuesta a sus requerimientos de información?',
        information_quality_label: '6. ¿Cómo califica la calidad y la exactitud de la información proporcionada por SEDECO?',
        future_touch_probability_label: '7. ¿Cuáles son las probabilidades de que nos contacte nuevamente en el futuro?',
        recommendation_probability_label: '8. ¿Cuáles son las probabilidades de que nos recomiende a otras personas?',
        suggestion_label: '9. Sí usted tiene algún comentario adicional o cualquier sugerencia en cuanto a como nosotros podríamos mejorar los servicios que le proporcionamos, por favor escriba sus comentarios *',
        quality_options: [
            {
                label: 'Excelente',
                value: 'Excelente'
            },
            {
                label: 'Bien',
                value: 'Bien'
            },
            {
                label: 'Regular',
                value: 'Regular'
            },
            {
                label: 'Malo',
                value: 'Malo'
            }
        ],
        frequency_options: [
            {
                label: 'Muy probable',
                value: 'Muy probable'
            },
            {
                label: 'Algo probable',
                value: 'Algo probable'
            },
            {
                label: 'Probable',
                value: 'Probable'
            },
            {
                label: 'Poco probable',
                value: 'Poco probable'
            }
        ],
        success_message: 'Encuesta enviada con éxito',
        failed_message: 'Algo salio mal',
        welcome: 'Encuesta Satisfacción del Cliente',
        welcome_message_1: 'Estimado Inversionista',
        welcome_message_2: 'La Secretaria de Desarrollo Económico  está comprometida en promover el desarrollo económico sostenido en beneficio de los jaliscienses a través de un Sistema de Gestión de Calidad y mejora continua en nuestros procesos que impulsa la competitividad e innovación en los sectores productivos, potenciando la diversidad y las capacidades de cada región.',
        welcome_message_3: 'En base a lo anterior y con objeto de buscar una mejora continua en nuestros procesos de servicio y aumentar la satisfacción de nuestros clientes, solicitamos su retroalimentación de los servicios recibidos por parte de la Dirección General de Atracción de Inversión de la SEDECO; anexo encontrará un enlace donde podrá contestar (en línea) una encuesta que no le tomara más de 3 minutos contestar.',
        welcome_message_4: 'Agradecemos mucho su retroalimentación y reiteramos nuestro compromiso de apoyar el crecimiento de sus operaciones en el Estado de Jalisco.`',
        sendSurveyButton: 'Enviar encuesta',
        requiredMessage: 'Este campo es requerido'
    },
    'en': {
        communication_label: '1. How do you rate your first contact with SEDECO? *',
        first_touch_label: '2. Rate our level of understanding of your business needs.',
        comprehension_level_label: '3. How clear were our communications with you?',
        experience_acknowledge_label: '4. How do you rate the experience and knowledge of the executives in SEDECO?',
        response_time_label: '5. How do you rate our response time to your requests for information?',
        information_quality_label: '6. How do you rate the quality and accuracy of the information provided by SEDECO?',
        future_touch_probability_label: '7. What are the possibilities that you will contact us again in the future?',
        recommendation_probability_label: '8. What are the possibilities that you will recommend us to others?',
        suggestion_label: '9. If you have any additional comment or suggestions regarding how we could improve our services provided to you, please enter them in the box below. *',
        quality_options: [
            {
                label: 'Excellent',
                value: 'Excelente'
            },
            {
                label: 'Good',
                value: 'Bien'
            },
            {
                label: 'Regular',
                value: 'Regular'
            },
            {
                label: 'Bad',
                value: 'Malo'
            }
        ],
        frequency_options: [
            {
                label: 'Certain',
                value: 'Muy probable'
            },
            {
                label: 'Very Likely ',
                value: 'Algo probable'
            },
            {
                label: 'Unlikely',
                value: 'Probable'
            },
            {
                label: 'Very Unlikely',
                value: 'Poco probable'
            }
        ],
        success_message: 'Survey sent successfully',
        failed_message: 'Something went wrong',
        welcome: 'Customer Services Survey',
        welcome_message_1: 'Dear Investor',
        welcome_message_2: 'The Secretariat of Economic Development of Jalisco (SEDECO) is committed to the promotion of a sustainable economic development for the State of Jalisco through a Quality System that will allow us to increase our competiveness and promote innovation and development in all our productive sectors and regions of our State.',
        welcome_message_3: 'We need your feedback on the quality of our services, in order to continue improving our customer satisfaction level. We invite you to take a 3 minutes survey. ',
        welcome_message_4: 'We thank you in advanced for your feedback and we reiterate our commitment with your project.',
        sendSurveyButton: 'Send survey',
        requiredMessage: 'This field is required'
    }
}