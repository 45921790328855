import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { REQUIRED, MIN_LENGTH } from 'constants/Validations'
import api from 'services/api';

const initialValues = {
  password: '',
  confirmPassword: ''
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
      .required(REQUIRED)
      .min(6, MIN_LENGTH(6)),
  confirmPassword: Yup.string()
      .required(REQUIRED)
      .oneOf([Yup.ref("password")], "Las contraseñas deben ser iguales")
})

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [activatedAccount, setActivatedAccount] = useState(false)

  const submit = async (values) => {
    setLoading(true)
    const data = {
      token: props.match.params.token,
      password: values.password
    },
      response = await api.user.createPassword(data)
    
    setLoading(false)
    if (response.success) {
      setActivatedAccount(true);
    } else {
      setMessage(response.message || 'Algo salio mal');
    }
  }

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img
            src={require("assets/images/logoSedeco.png")}
            alt="logo"
            style={{ width: '100%' }}
          />
        </div>

        <div className="app-login-content">
          <div className="app-login-header text-center mb-4">
            <h1>Crear contraseña</h1>
          </div>
          <p>Para terminar de configurar su cuenta ingrese una contraseña</p>

          <div className="app-login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submit}
            >
              {({ values, errors, touched, handleChange, setFieldTouched }) => {
                const change = (name, e) => {
                    e.persist()
                    handleChange(e)
                    setFieldTouched(name, true, false)
                }
                return (
                  <Form>
                    <fieldset>
                      <TextField
                        type="password"
                        name="password"
                        label="Contraseña"
                        fullWidth
                        value={values.password}
                        onChange={change.bind(null, "password")}
                        helperText={touched.password ? errors.password : ''}
                        error={touched.password && !!errors.password}
                        margin="normal"
                        className='m-0 mb-2'
                      />
                      <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirmar contraseña"
                        fullWidth
                        value={values.confirmPassword}
                        onChange={change.bind(null, "confirmPassword")}
                        helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                        error={touched.confirmPassword && !!errors.confirmPassword}
                        margin="normal"
                        className='m-0 mb-5'
                      />
                      {message && (
                        <div>
                          <h2 style={{ color: 'red' }}>{message}</h2>
                        </div>
                      )}

                      {activatedAccount &&
                        <div>
                          <h2 style={{ color: 'green' }}>Su cuenta se ha activado con éxito!</h2>
                        </div>
                      }

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        {
                          activatedAccount
                          ? <Button onClick={() => props.history.push('/login')} fullWidth variant="contained" color="primary">
                              Ir a login
                            </Button>
                          : <Button type="submit" fullWidth variant="contained" color="primary">
                            Guardar
                          </Button>
                        }
                      </div>
                    </fieldset>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>

      </div>
      {
        loading &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
    </div>
  );
}

export default ResetPassword
