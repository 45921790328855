import React from "react";
import { connect } from "react-redux";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import image from "assets/images/path3785.png";
import storage from "services/storage";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";

class UserInfoPopup extends React.Component {
  state = {
    redirect: false
  };
  render() {
    const data = storage.getData();
    return (
      <div>
        <div className="user-profile">
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">
              {data ? data.user.name + " " + data.user.lastName : ""}
            </h4>
            <small>{data ? data.user.type : ""}</small>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            className="bg-purple text-white"
            onClick={() => {
              storage.removeToken();
              this.setState({ redirect: true });
            }}
          >
            {this.state.redirect && <Redirect to="/login" />}
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
            <span>Cerrar sesión</span>
          </Button>
        </div>

        {/*<span className="jr-link dropdown-item text-muted"></span>*/}
      </div>
    );
  }
}

export default connect(null, { userSignOut })(UserInfoPopup);
