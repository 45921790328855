import { sendRequest } from "util/utils";

const sendSurvey = async (survey) => {
    const route = "/survey";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
          },
        body: JSON.stringify(survey),
    };
    return await sendRequest(route, request);
};

export default {
    sendSurvey
};
