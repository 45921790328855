const getData = () => {
  return JSON.parse(localStorage.getItem("data"));
};

const getToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      token = data.token.access_token ? data.token.access_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
};

const getRefreshToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      token = data.token.refresh_token ? data.token.refresh_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
}

const updateToken = (token) => {
  const data = JSON.parse(localStorage.getItem("data"));
  data.token = token;
  setStorage(data);
}

const setStorage = data => {
  localStorage.setItem("data", JSON.stringify(data));
};

const removeToken = () => {
  let data = JSON.parse(localStorage.getItem("data"));
  if (!!data) {
    data = "";
  }
  return setStorage(data);
};

export default {
  getData,
  getToken,
  getRefreshToken,
  updateToken,
  setStorage,
  removeToken
};
