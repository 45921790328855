import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { REQUIRED, EMAIL } from "constants/Validations";
import api from "services/api";

const initialValues = {
  email: "",
  password: ""
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(EMAIL)
    .required(REQUIRED),
  password: Yup.string().required(REQUIRED)
});

const SignIn = props => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const submit = async values => {
    setMessage("");
    setLoading(true);
    let response = await api.user.login(values);
    if (response.success) {
      localStorage.setItem("data", JSON.stringify(response));
      setLoading(false);
      props.history.push("/app/projects");
    } else {
      setMessage(response.message || "Algo salio mal");
      setLoading(false);
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <img
            src={require("assets/images/logoSedeco.png")}
            alt="logo"
            style={{ width: "100%" }}
          />
        </div>

        <div className="app-login-content">
          <div className="app-login-header text-center mb-4">
            <h1>Iniciar sesión</h1>
          </div>

          <div className="app-login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submit}
            >
              {({ values, errors, touched, handleChange, setFieldTouched }) => {
                const change = (name, e) => {
                  e.persist();
                  handleChange(e);
                  setFieldTouched(name, true, false);
                };
                return (
                  <Form>
                    <fieldset>
                      <TextField
                        label="Correo electrónico"
                        name="email"
                        fullWidth
                        onChange={change.bind(null, "email")}
                        value={values.email}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        margin="normal"
                        className="m-0 mb-2"
                        // className="mt-1 my-sm-3"
                      />
                      <TextField
                        type="password"
                        name="password"
                        label="Contraseña"
                        fullWidth
                        value={values.password}
                        onChange={change.bind(null, "password")}
                        helperText={touched.password ? errors.password : ""}
                        error={touched.password && Boolean(errors.password)}
                        margin="normal"
                        className="m-0 mb-5"
                        // className="mt-0 my-sm-3"
                      />
                      {message && (
                        <div>
                          <h2 style={{ color: "red" }}>{message}</h2>
                        </div>
                      )}

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        {loading ? (
                          <div className="text-center w-100">
                            <CircularProgress />
                          </div>
                        ) : (
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Iniciar Sesión
                          </Button>
                        )}
                      </div>
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Link to="/forgot_password">
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </div>
                    </fieldset>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>

      {/* {!!message && NotificationManager.error(message)}
      <NotificationContainer /> */}
    </div>
  );
};

export default SignIn;
