export const REQUIRED = "Este campo es requerido";
export const NUMBER = "Debe ingresar un número válido";
export const INTEGER = "El número no puede contener decimales";
export const EMAIL = "Correo electronico invalido. Ejemplo: ejemplo@email.com";
export const PERCENTAGE = "Valor invalido. Ejemplo: 50 o 50.01";
export const CELLPHONE =
  "Numero de celular invalido. Ejemplo: +52 33 3333 3333";
export const PHONE = "Numero de telefono invalido. Ejemplo: 33 3333 3333";
export const NO_NEG = "Debe ingresar un valor positivo";
export const MIN_LENGTH = length =>
  `Este campo debe contener al menos ${length} caracteres`;
export const MAX_LENGTH = length =>
  `Este campo no debe contener mas de ${length} caracteres`;
export const MIN = min => `El valor mínimo es ${min}`;
export const MAX = max => `El valor máximo es ${max}`;
export const MONEY = "El valor ingresado no es valido";
