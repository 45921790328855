import { sendRequest } from "util/utils";
import storage from "services/storage";

const getProjectsList = async _ => {
  const route = "/projects";
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};
const editProject = async (id, project) => {
  const route = `/projects/${id}`;
  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(project)
  };
  return await sendRequest(route, request);
};

const getProjectsLogs = async id => {
  const route = `/logs/${id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};

const createProject = async project => {
  const route = "/projects";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(project)
  };
  return await sendRequest(route, request);
};

const getProjectsById = async id => {
  const route = `/projects/${id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};

const createInvestmentCase = async investment_case => {
  const route = "/investment_cases";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(investment_case)
  };
  return await sendRequest(route, request);
};
const getInvestmentCase = async id => {
  const route = `/investment_cases/${id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};

const createCommitment = async commitment => {
  const route = "/commitments";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(commitment)
  };
  return await sendRequest(route, request);
};

const getCommitments = async id => {
  const route = `/commitments/${id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};

const createIncentive = async incentive => {
  const route = "/incentives";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(incentive)
  };
  return await sendRequest(route, request);
};

const getIncentive = async id => {
  const route = `/incentives/${id}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    }
  };
  return await sendRequest(route, request);
};
const approveIncentive = async (id, incentive) => {
  const route = `/incentives/${id}/approve`;
  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage.getToken()}`
    },
    body: JSON.stringify(incentive)
  };
  return await sendRequest(route, request);
};

export default {
  approveIncentive,
  getProjectsList,
  createProject,
  getProjectsById,
  getProjectsLogs,
  editProject,
  createInvestmentCase,
  getInvestmentCase,
  createIncentive,
  getIncentive,
  createCommitment,
  getCommitments
};
