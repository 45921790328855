import { sendRequest } from "util/utils";
import storage from "services/storage";

const getMainContacts = async (id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/main_contacts/${id}`,
      request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        }
      };
    return await sendRequest(route, request);
};

const createMainContact = async mainContact => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/main_contacts`,
        request = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(mainContact)
        };
    return await sendRequest(route, request);
};

const editMainContact = async (mainContact, id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/main_contacts/${id}`,
        request = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(mainContact)
        };
    return await sendRequest(route, request);
};

const deleteMainContact = async (id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/main_contacts/${id}`,
        request = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
        };
    return await sendRequest(route, request);
};

export default {
    getMainContacts,
    createMainContact,
    editMainContact,
    deleteMainContact
};
